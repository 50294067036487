
@media screen {
  #vue-3-drawer {
    @apply tw-w-[50vw] tw-h-0 tw-max-w-0 tw-overflow-hidden
      tw-block tw-bg-white
      tw-transition-[max-width] tw-duration-300 tw-ease-in-out;
  }
  .kipu-vue-drawer-wrapper {
    &.kipu-vue-drawer-open {
      @apply tw-flex tw-flex-row tw-justify-between
        tw-h-screen tw-w-screen tw-overflow-hidden #{!important};

      main {
        @apply tw-max-w-full tw-h-full tw-overflow-auto tw-flex-1 #{!important};
        scrollbar-gutter: stable;
      }

      #vue-3-drawer {
        @apply tw-h-screen tw-max-w-3xl #{!important};
        scrollbar-gutter: stable;
      }
    }
  }
}
@media print {
  #vue-3-drawer {
    @apply tw-hidden;
  }
}
